import {
  ExternalLinkIcon,
  PlusIcon,
  UploadIcon,
  DownloadIcon,
  ShareIcon,
  ChatAltIcon,
  PhotographIcon,
  PlayIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  ChevronDownIcon,
  MailIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import React from "react";
import RevertIconSolid from "../../primaryrender/editor/EditPhotoModal/RevertIconSolid";
import GenerateMediaIcon from "../../primaryrender/editor/NewPost/GenerateMediaIcon";

export interface GrayLinkProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  alignment?: string;
  text: string;
  size: string;
  onClickFunc?: any;
  width?: string;
  height?: string;
  spacing?: string;
  icon?: any;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
  saveDisabled?: boolean;
  trailingElement?: any;
  customTextColor?: string;
}

export default function GrayLink({
  text,
  size,
  onClickFunc,
  icon,
  iconPlacement = "leading",
  id,
  customStyle,
  saveDisabled,
  trailingElement,
  customTextColor,
}: GrayLinkProps) {
  let style = `${
    customTextColor ? customTextColor : "text-gray-600"
  }  hover:text-gray-800 transition-colors duration-150 ease-in-out font-medium`;
  let textSize;

  switch (size) {
    case "sm":
      textSize = "text-xs";
      break;
    case "md":
      textSize = "text-sm";
      break;
    case "lg":
      textSize = "text-base";
      break;
  }

  function getIconStyle(size: string) {
    if (size === "sm" || size === "md") return "h-4 w-4";
    return "h-5 w-5";
  }

  if (icon) {
    switch (icon) {
      case "share":
        icon = <ShareIcon className={getIconStyle(size)} />;
        break;
      case "chat":
        icon = <ChatIcon className={getIconStyle(size)} />;
        break;
      case "mail":
        icon = <MailIcon className={getIconStyle(size)} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={getIconStyle(size)} />;
        break;
      case "plus":
        icon = <PlusIcon className={getIconStyle(size)} />;
        break;
      case "delete":
        icon = <TrashIcon className={getIconStyle(size)} />;
        break;
      case "upload":
        icon = <UploadIcon className={getIconStyle(size)} />;
        break;
      case "export":
        icon = <DownloadIcon className={getIconStyle(size)} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={getIconStyle(size)} />;
        break;
      case "chevron-left-arrow":
        icon = <ChevronLeftIcon className={getIconStyle(size)} />;
        break;
      case "chevron-down-arrow":
        icon = <ChevronDownIcon className={getIconStyle(size)} />;
        break;
      case "revert":
        icon = <RevertIconSolid />;
        break;
      case "chat-alt":
        icon = <ChatAltIcon className={getIconStyle(size)} />;
        break;
      case "photograph":
        icon = <PhotographIcon className={getIconStyle(size)} />;
        break;
      case "play":
        icon = <PlayIcon className={getIconStyle(size)} />;
        break;
      case "generate-media":
        icon = <GenerateMediaIcon className={getIconStyle(size)} />;
        break;
      case "sparkles":
        icon = <SparklesIcon className={getIconStyle(size)} />;
        break;
    }
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  return (
    <>
      <button
        id={id || ""}
        data-cy={id}
        className={`${textSize} ${style} flex items-center cursor-pointer ${
          saveDisabled && "cursor-not-allowed"
        }`}
        onClick={onClickFunc}
        disabled={saveDisabled}
      >
        {icon && iconPlacement !== "lagging" && (
          <div className="mr-1">{icon}</div>
        )}
        {text}
        {trailingElement && trailingElement}
        {icon && iconPlacement === "lagging" && (
          <div className="ml-1">{icon}</div>
        )}
      </button>
    </>
  );
}
